import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

export interface UserPermissionsOptions {
  CondominioId?: string
  ObraId?: string
}

/**
 * Carrega lista de permissões do usuário dado um condominio
 */
export async function fetchUserPermissions(options: UserPermissionsOptions) {
  try {
    const { data } = await api.get<string[]>('Usuario/permissao', {
      params: options
    })

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export enum Rules {
  AddCommentWork = 'AdicionarComentario_Obra',
  RequestFinalSurvey = 'SolicitarVistoriaFinal_Obra',
  FinishWork = 'FinalizarObra_Obra',
  CloseNonCompliance = 'Encerrar_NaoConformidade',
  EvaluateItemChecklistWork = 'AvaliarItemChecklist_Obra',
  ViewWorkEvent = 'VisualizarEventos_Obra',
  ViewUserLot = 'VisualizarPessoa_Lote',
  ViewPersonCondominium = 'VisualizarPessoa_Condominio',
  ViewPersonWork = 'VisualizarPessoa_Obra',
  CreateBlock = 'Cadastrar_Quadra',
  CreateNonComplianceType = 'Cadastrar_TipoNaoConformidade',
  CreateItemChecklistType = 'Cadastrar_TipoItemChecklist',
  CreatePaymentAttachment = 'Cadastrar_ComprovantePagamento',
  CreateNonCompliance = 'Cadastrar_NaoConformidade',
  CreateLot = 'Cadastrar_Lote',
  CreateSupportMaterial = 'Cadastrar_MaterialApoio',
  CreateUser = 'Cadastrar_Usuario',
  CreateCondominium = 'Cadastrar_Condominio',
  CreateWork = 'Cadastrar_Obra',
  CreateStreet = 'Cadastrar_Rua',
  CreateDocument = 'Cadastrar_DocumentoTecnico',
  SubmitWorkToAnalisis = 'SubmeterParaAnalise_Obra',
  ViewBlock = 'Visualizar_Quadra',
  ViewNonComplianceType = 'Visualizar_TipoNaoConformidade',
  ViewReport = 'Visualizar_Relatorio',
  ViewItemChecklistType = 'Visualizar_TipoItemChecklist',
  ViewPaymentAttachment = 'Visualizar_ComprovantePagamento',
  ViewNonCompliance = 'Visualizar_NaoConformidade',
  ViewCity = 'Visualizar_Cidade',
  ViewState = 'Visualizar_Estado',
  ViewLot = 'Visualizar_Lote',
  ViewSupporMaterial = 'Visualizar_MaterialApoio',
  ViewUser = 'Visualizar_Usuario',
  ViewCondominium = 'Visualizar_Condominio',
  ViewWork = 'Visualizar_Obra',
  ViewStreet = 'Visualizar_Rua',
  ViewDocument = 'Visualizar_DocumentoTecnico',
  SubmitProjectToAnalisis = 'SubmeterProjetoParaAnalise_Obra',
  SendProject = 'EnviarProjeto_Obra',
  ViewProject = 'VisualizarProjeto_Obra',
  SendTax = 'EnviarTaxa_Obra',
  AddUserLot = 'AdicionarPessoa_Lote',
  AddUserCondominium = 'AdicionarPessoa_Condominio',
  AddUserWork = 'AdicionarPessoa_Obra',
  EvaluateProject = 'AvaliarProjeto_Obra',
  SendProjectReviewd = 'EnviarProjetoRevisado_Obra',
  EditBlock = 'Editar_Quadra',
  EditNonComplianceType = 'Editar_TipoNaoConformidade',
  EditItemChecklistType = 'Editar_TipoItemChecklist',
  EditPaymentAttachment = 'Editar_ComprovantePagamento',
  EditNonCompliance = 'Editar_NaoConformidade',
  EditLot = 'Editar_Lote',
  EditSupportMaterial = 'Editar_MaterialApoio',
  EditUser = 'Editar_Usuario',
  EditCondominium = 'Editar_Condominio',
  EditWork = 'Editar_Obra',
  EditStreet = 'Editar_Rua',
  EditDocument = 'Editar_DocumentoTecnico',
  EvaluatePaymentAttachementWork = 'AvaliarComprovante_Obra',
  ReactiveProcess = 'ReativarProcesso_Obra',
  SendNonComplianceCI = 'EnviarCI_NaoConformidade',
  EvaluatePaymentAttachement = 'Avaliar_ComprovantePagamento',
  EvaluateNonCompliance = 'Avaliar_NaoConformidade',
  RemoveUserLot = 'RemoverPessoa_Lote',
  RemoveUserCondominium = 'RemoverPessoa_Condominio',
  RemoveUserWork = 'RemoverPessoa_Obra',
  CloseProcess = 'EncerrarProcesso_Obra',
  StartWork = 'IniciarObra_Obra',
  DeleteBlock = 'Excluir_Quadra',
  DeleteNonComplianceType = 'Excluir_TipoNaoConformidade',
  DeleteItemChecklistType = 'Excluir_TipoItemChecklist',
  DeletePaymentAttachment = 'Excluir_ComprovantePagamento',
  DeleteNonCompliance = 'Excluir_NaoConformidade',
  DeleteLot = 'Excluir_Lote',
  DeleteSupportMaterial = 'Excluir_MaterialApoio',
  DeleteUser = 'Excluir_Usuario',
  DeleteCondominium = 'Excluir_Condominio',
  DeleteWork = 'Excluir_Obra',
  DeleteStreet = 'Excluir_Rua',
  DeleteDocument = 'Excluir_DocumentoTecnico',
  SendItemChecklistWork = 'EnviarItemChecklist_Obra',
  SendProjectWithNotes = 'EnviarProjetoComObservacao_Obra',
  ViewDashboardCondominium = 'VisualizarDashboard_Condominio',
  Reanalysis = 'Reanalisar_Obra',
  markWorkAsInhabited = 'MarcarHabitada_Obra',
  InformarAreaFinalObra = 'InformarAreaFinal_Obra',
  SendNonComplianceFine = 'EnviarTaxa_NaoConformidade',
  StartProcessReanalysis = 'IniciarReanalise_Obra',
  CloseProcessReanalysis = 'FinalizarReanalise_Obra',
  ArchiveWork = 'Arquivar_Obra',
  UnarchiveWork = 'Desarquivar_Obra',
  SendReleaseLetter = 'EnviarCartaLiberacao_Obra',
  EvaluateReleaseLetter = 'AvaliarCartaLiberacao_Obra',
  JoinLots = 'Remembrar_Lote',
  RemoveCaveat = 'RemoverRessalvaDocumentacao_Obra',
  CadastrarEmailCondominio = 'Cadastrar_EmailCondominio',
  EditarEmailCondominio = 'Editar_EmailCondominio',
  ExcluirEmailCondominio = 'Excluir_EmailCondominio',
  VisualizarEmailCondominio = 'Visualizar_EmailCondominio',
  CadastrarDisciplina = 'Cadastrar_Disciplina',
  ExcluirDisciplina = 'Excluir_Disciplina',
  EditarDisciplina = 'Editar_Disciplina',
  EditarDocumentoTecnico = 'Editar_DocumentoTecnico',
  ExcluirDocumentoTecnico = 'Excluir_DocumentoTecnico',
  CadastrarDocumentoTecnico = 'Cadastrar_DocumentoTecnico',
  VisualizarDocumentoTecnico = 'Visualizar_DocumentoTecnico',
  RecusarOrcamentoItemManutencao = 'RecusarOrcamento_ItemManutencao',
  EscolherOrcamentoItemManutencao = 'EscolherOrcamento_ItemManutencao',
  AdicionarOrcamentoItemManutencao = 'AdicionarOrcamento_ItemManutencao',
  AdicionarContratoItemManutencao = 'AdicionarContrato_ItemManutencao',
  AutorizarExecucaoItemManutencao = 'AutorizarExecucao_ItemManutencao',
  LiberarEscolhaOrcamentoItemManutencao = 'LiberarEscolhaOrcamento_ItemManutencao',
  AdicionarPreventivaItemManutencao = 'AdicionarPreventiva_ItemManutencao',
  VisualizarSistema = 'VisualizarSistema',
  VisualizarDisciplina = 'Visualizar_Disciplina',
  IniciarObraIrregularObra = 'IniciarObraIrregular_Obra',
  AcessoTotalObra = 'AcessoTotal_Obra',
  VisualizarDashboardNaoConformidadeCondominio = 'VisualizarDashboardNaoConformidade_Condominio',
  VisualizarDashboardManutencaoCondominio = 'VisualizarDashboardManutencao_Condominio',
  VisualizarAcessoPessoasCondominio = 'VisualizarAcessoPessoas_Condominio',
  VisualizarEmpresas = 'Visualizar_Empresas',
  CadastrarEmpresa = 'Cadastrar_Empresa',
  EditarEmpresa = 'Editar_Empresa',
  ExcluirEmpresa = 'Excluir_Empresa',
  VisualizarLiberacaoAcesso = 'Visualizar_LiberacaoAcesso',
  CadastrarLiberacaoAcesso = 'Cadastrar_LiberacaoAcesso',
  ExcluirLiberacaoAcesso = 'Excluir_LiberacaoAcesso',
  VisualizarPessoaObra = 'Visualizar_PessoaObra',
  CadastrarPessoaObra = 'Cadastrar_PessoaObra',
  ExcluirPessoaObra = 'Excluir_PessoaObra',
  EditarPessoaObra = 'Editar_PessoaObra',
  RelatarProblema = 'RelatarProblema',
  CadastrarManutencao = 'CadastrarManutencao',
  VisualizarManutencao = 'VisualizarManutencao',
  EditarManutencao = 'EditarManutencao',
  ExcluirManutencao = 'ExcluirManutencao',
  ExecutaManutencao = 'ExecutaManutencao',
  CadastrarOrdemDeServico = 'CadastrarOrdemDeServico',
  VisualizarOrdemDeServico = 'VisualizarOrdemDeServico',
  EditarOrdemDeServico = 'EditarOrdemDeServico',
  ExcluirOrdemDeServico = 'ExcluirOrdemDeServico',
  EnviarContratoOrdemDeServico = 'EnviarContratoOrdemDeServico',
  EnviarOrcamentoOrdemDeServico = 'EnviarOrcamentoOrdemDeServico',
  EscolherOrcamentoOrdemDeServico = 'EscolherOrcamentoOrdemDeServico',
  LiberarExecucaoOrdemDeServico = 'LiberarExecucaoOrdemDeServico',
  ExcluirOrcamentoOrdemDeServico = 'ExcluirOrcamentoOrdemDeServico',
  ExcluirContratoOrdemDeServico = 'ExcluirContratoOrdemDeServico',
  CadastrarSistema = 'CadastrarSistema',
  EditarSistema = 'EditarSistema',
  ExcluirSistema = 'ExcluirSistema',
  CadastrarLocal = 'CadastrarLocal',
  VisualizarLocal = 'VisualizarLocal',
  EditarLocal = 'EditarLocal',
  ExcluirLocal = 'ExcluirLocal',
  CadastrarItemManutencao = 'CadastrarItemManutencao',
  VisualizarItemManutencao = 'VisualizarItemManutencao',
  EditarItemManutencao = 'EditarItemManutencao',
  ExcluirItemManutencao = 'ExcluirItemManutencao',
  AdicionarVisitaLocal = 'AdicionarVisitaLocal',
  CadastrarLicenca = 'CadastrarLicenca',
  VisualizarLicenca = 'VisualizarLicenca',
  ExcluirLicenca = 'ExcluirLicenca',
  VisualizarEmails = 'Visualizar_Emails',
  VisualizarEmpresaManutencao = 'VisualizarEmpresaManutencao',
  EditarEmpresaManutencao = 'EditarEmpresaManutencao',
  CadastrarEmpresaManutencao = 'CadastrarEmpresaManutencao',
  ExcluirEmpresaManutencao = 'ExcluirEmpresaManutencao',
  CancelarManutencao = 'CancelarManutencao',
  EnviarNotaFiscalOrdemDeServico = 'EnviarNotaFiscalOrdemDeServico',
  AcionarGarantiaOrdemDeServico = 'AcionarGarantiaOrdemDeServico',
  EditarLicenca = 'EditarLicenca',
  ExcluirNotaFiscalOrdemDeServico = 'ExcluirNotaFiscalOrdemDeServico',
  CadastrarCategoriaNaoConformidade = 'CadastrarCategoriaNaoConformidade',
  AtualizarCategoriaNaoConformidade = 'AtualizarCategoriaNaoConformidade',
  ExcluirCategoriaNaoConformidade = 'ExcluirCategoriaNaoConformidade',
  VisualizarCategoriaNaoConformidade = 'VisualizarCategoriaNaoConformidade',
  AdicionarTiposDeChecklistDeRotinas = 'AdicionarTiposdeChecklistdeRotinasWEB_AdicionarTiposDeChecklistDeRotinasWEB',
  VisualizarTiposDeChecklistDeRotinas = 'VisualizarTiposDeChecklistDeRotinasWEB_VisualizarTiposDeChecklistDeRotinasWEB',
  EditarTiposDeChecklistDeRotinas = 'EditarTiposDeChecklistDeRotinasWEB_EditarTiposDeChecklistDeRotinasWEB',
  ExcluirTiposDeChecklistDeRotinas = 'ExcluirTiposDeChecklistDeRotinasWEB_ExcluirTiposDeChecklistDeRotinasWEB',
  AdicionarTiposDeRotinas = 'AdicionarTiposDeRotinasWEB_AdicionarTiposDeRotinasWEB',
  VisualizarTiposDeRotinas = 'VisualizarTiposDeRotinasWEB_VisualizarTiposDeRotinasWEB',
  EditarTiposDeRotinas = 'EditarTiposdeRotinasWEB_EditarTiposDeRotinasWEB',
  ExcluirTiposDeRotinas = 'ExcluirTiposdeRotinasWEB_ExcluirTiposDeRotinasWEB',
  DownloadRelatorio = 'DownloadRelatórioWEB_DownloadRelatórioWEB',
  AdicionarRotinasAoProcesso = 'AdicionarRotinasAoProcessoWEB_AdicionarRotinasAoProcessoWEB',
  VisualizarDetalhesDaRotinaNoProcesso = 'VisualizarDetalhesDaRotinaNoProcessoWEB_VisualizarDetalhesDaRotinaNoProcesso',
  RotinasDeObraImprimirHistorico = 'RotinasDeObraImprimirHistóricoWEB_RotinasDeObraImprimirHistóricoWEB',
  ExcluirRotinaDoProcesso = 'ExcluirRotinaDoProcessoWEB_ExcluirRotinaDoProcessoWEB',
  VisualizarEmailsGerais = 'VisualizarEmailsGerais',
  VisualizarRelatorioManutencao = 'VisualizarRelatorioManutencao',
  VisualizarRotinaManutencao = 'VisualizarRotinaManutencao',
  GerenciaRotinaManutencao = 'GerenciaRotinaManutencao',
  ReabrirNaoConformidade = 'ReabrirNaoConformidade'
}
